import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { isDiscordCommunityOPenSelector } from '../../../templates/Challenges/redux/selectors';
import { closeModal } from '../../../templates/Challenges/redux/actions';
import { Modal } from '../../ui/Modal';
import Button from '../../ui/Button';
import { ButtonTypes } from '../../ui/Button/button-types';
import VideoPlayer from '../../../templates/Challenges/components/video-player';
import { discordLocation } from '../../../../../config/env.json';

import './discord-community-modal.css';

interface DiscordCommunityModalProps {
  readonly isOpen: boolean;
  readonly close: () => void;
}

const mapStateToProps = createSelector(
  isDiscordCommunityOPenSelector,
  (isOpen: boolean) => ({
    isOpen
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal('discordCommunity')
    },
    dispatch
  );

function DiscordCommunityModal({
  isOpen,
  close
}: DiscordCommunityModalProps): JSX.Element {
  const { t } = useTranslation();

  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  useEffect(() => {
    const storedValue = localStorage.getItem('discordModalDoNotShowAgain');
    if (storedValue === 'true') {
      setDoNotShowAgain(true);
    }
  }, []);

  const handleHide = () => {
    close();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setDoNotShowAgain(checked);
    localStorage.setItem('discordModalDoNotShowAgain', checked.toString());
  };

  return (
    <Modal.Root animation={true} show={isOpen} onHide={handleHide}>
      <Modal.Header closeButton>
        {t('learn.discord-community-modal.title')}
      </Modal.Header>
      <Modal.Body>
        <p>{t('learn.discord-community-modal.message')}</p>
        <VideoPlayer videoId='DoAua2KtsW0' videoStyle={{ height: '350px' }} />
        <div className='checkbox-container'>
          <span>{t('learn.discord-community-modal.dont-show-again')}</span>
          <input
            type='checkbox'
            checked={doNotShowAgain}
            onChange={handleCheckboxChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          buttonType={ButtonTypes.Primary}
          style={{ height: '48px', width: '100%' }}
          href={discordLocation}
          target='_blank'
        >
          {t('learn.discord-community-modal.button-access')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

DiscordCommunityModal.displayName = 'DiscordCommunityModal';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscordCommunityModal);
